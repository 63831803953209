import styled from 'styled-components'
import { colors, fonts } from '../theme'
import { MD_PX, SM_PX } from '../../header/Header.styled'
import Link from 'next/link'

export const ContainerItem = styled(Link)`
    display: flex;
    align-items: top;
    align-items: center;
    height: 20px;
`

export const Item = styled.span`
    ${fonts.jotaBodyXSmall()};
    color: ${colors.gray.jotaGrayDark};
    margin: 0 4px;
`

export const LastItem = styled.span`
    ${fonts.jotaBodyXSmall()};
    color: ${colors.gray.jotaGrayDark};
    margin: 0 4px;
    font-weight: 700;
    line-height: inherit !important;
    height: 24px;
    display: flex;
    align-items: center;
`

export const ClickableItem = styled.span`
    height: 22px;
    display: flex;
    align-items: center;
    &:hover {
        text-decoration: underline;
        color: ${colors.gray.jotaGrayDarker};
        cursor: pointer;
    }
`

export const SelectedItem = styled.span`
    ${fonts.jotaBodyXSmall()};
    color: ${colors.gray.jotaGrayDark};
    margin: 0 4px;
    font-weight: 700;
    line-height: inherit !important;
    height: 24px;
    display: flex;
    align-items: center;
`
export const BreadcrumbContainerInternal = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
`
export const BreadcrumbContainer = styled.div`
    padding: 0 0;

    @media only screen and (max-width: ${MD_PX}) {
        padding: 0 0;
    }

    @media only screen and (max-width: ${SM_PX}) {
        padding: 0 0;
    }

    & div[data-testid='breadcrumb-container'] div:first-of-type span:hover {
        cursor: pointer;
        text-decoration: underline;
        color: ${colors.gray.jotaGrayDarker};
    }

    & span:hover {
        color: #73757f;
    }
`

export const BreadIcon = styled.span`
    background: red;
`
