/**
 * Jota Material Home Icon component
 * @param {object} props - The properties passed to the component
 * @return {JSX.Element} The SVG element representing the home icon
 */
export function JotaMaterialHome(props: any) {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M9.99998 19V14H14V19C14 19.55 14.45 20 15 20H18C18.55 20 19 19.55 19 19V12H20.7C21.16 12 21.38 11.43 21.03 11.13L12.67 3.59997C12.29 3.25997 11.71 3.25997 11.33 3.59997L2.96998 11.13C2.62998 11.43 2.83998 12 3.29998 12H4.99998V19C4.99998 19.55 5.44998 20 5.99998 20H8.99998C9.54998 20 9.99998 19.55 9.99998 19Z'
                fill='current'
            />
        </svg>
    )
}

/**
 * Renders an SVG arrow based on the provided type.
 *
 * @param {object} props - The properties object.
 * @param {string} props.type - The type of arrow to render ('up', 'left', 'leftLarge', 'right', 'rightLarge').
 * @return {JSX.Element} The SVG element representing the arrow.
 */
export function JotaMaterialArrow(props: any) {
    let SVG_BODY
    switch (props.type) {
        case 'up':
            SVG_BODY = (
                <path
                    d='M11.29 8.70998L6.69997 13.3C6.30997 13.69 6.30997 14.32 6.69997 14.71C7.08997 15.1 7.71997 15.1 8.10997 14.71L12 10.83L15.88 14.71C16.27 15.1 16.9 15.1 17.29 14.71C17.68 14.32 17.68 13.69 17.29 13.3L12.7 8.70998C12.32 8.31998 11.68 8.31998 11.29 8.70998Z'
                    fill='current'
                />
            )
            break
        case 'left':
            SVG_BODY = (
                <path
                    d='M15.2925 6.2925C14.9025 5.9025 14.2725 5.9025 13.8825 6.2925L9.2925 10.8825C8.9025 11.2725 8.9025 11.9025 9.2925 12.2925L13.8825 16.8825C14.2725 17.2725 14.9025 17.2725 15.2925 16.8825C15.6825 16.4925 15.6825 15.8625 15.2925 15.4725L11.4125 11.5825L15.2925 7.7025C15.6825 7.3125 15.6725 6.6725 15.2925 6.2925Z'
                    fill='current'
                />
            )
            break
        case 'leftLarge':
            SVG_BODY = (
                <path
                    d='M16.6201 2.99006C16.1301 2.50006 15.3401 2.50006 14.8501 2.99006L6.54006 11.3001C6.15006 11.6901 6.15006 12.3201 6.54006 12.7101L14.8501 21.0201C15.3401 21.5101 16.1301 21.5101 16.6201 21.0201C17.1101 20.5301 17.1101 19.7401 16.6201 19.2501L9.38006 12.0001L16.6301 4.75006C17.1101 4.27006 17.1101 3.47006 16.6201 2.99006Z'
                    fill='current'
                />
            )
            break
        case 'right':
            SVG_BODY = (
                <path
                    d='M9.29006 6.71002C8.90006 7.10002 8.90006 7.73002 9.29006 8.12002L13.1701 12L9.29006 15.88C8.90006 16.27 8.90006 16.9 9.29006 17.29C9.68006 17.68 10.3101 17.68 10.7001 17.29L15.2901 12.7C15.6801 12.31 15.6801 11.68 15.2901 11.29L10.7001 6.70002C10.3201 6.32002 9.68006 6.32002 9.29006 6.71002Z'
                    fill='current'
                />
            )
            break
        case 'rightLarge':
            SVG_BODY = (
                <path
                    d='M7.37999 21.01C7.86999 21.5 8.65999 21.5 9.14999 21.01L17.46 12.7C17.85 12.31 17.85 11.68 17.46 11.29L9.14999 2.98005C8.65999 2.49005 7.86999 2.49005 7.37999 2.98005C6.88999 3.47005 6.88999 4.26005 7.37999 4.75005L14.62 12L7.36999 19.25C6.88999 19.73 6.88999 20.5301 7.37999 21.01Z'
                    fill='current'
                />
            )
            break
        default:
            SVG_BODY = (
                <path
                    d='M15.88 9.29006L12 13.1701L8.11998 9.29006C7.72998 8.90006 7.09998 8.90006 6.70998 9.29006C6.31998 9.68006 6.31998 10.3101 6.70998 10.7001L11.3 15.2901C11.69 15.6801 12.32 15.6801 12.71 15.2901L17.3 10.7001C17.69 10.3101 17.69 9.68006 17.3 9.29006C16.91 8.91006 16.27 8.90006 15.88 9.29006Z'
                    fill='current'
                />
            )
    }

    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            {SVG_BODY}
        </svg>
    )
}

/**
 * Renders an SVG arrow pointing to the right.
 *
 * @param {object} props - The properties object.
 * @return {JSX.Element} The SVG element representing the arrow.
 */
export function JotaMaterialArrowRight(props: any) {
    const SVG_BODY = (
        <path
            d='M9.29006 6.71002C8.90006 7.10002 8.90006 7.73002 9.29006 8.12002L13.1701 12L9.29006 15.88C8.90006 16.27 8.90006 16.9 9.29006 17.29C9.68006 17.68 10.3101 17.68 10.7001 17.29L15.2901 12.7C15.6801 12.31 15.6801 11.68 15.2901 11.29L10.7001 6.70002C10.3201 6.32002 9.68006 6.32002 9.29006 6.71002Z'
            fill='current'
        />
    )

    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            {SVG_BODY}
        </svg>
    )
}
