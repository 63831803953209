import { BreadcrumbItem } from './interfaces'

/**
 * Breadcrumb routes for PRO only
 * @type {Record<string, BreadcrumbItem>}
 */
export const breadcrumbRoutesPROOnly: Record<string, BreadcrumbItem> = {
    // Trabalhista
    '/trabalhista': {
        label: 'Trabalhista',
        slug: '/trabalhista',
        clickable: true
    },
    '/trabalhista/apostas-da-semana': {
        label: 'Apostas da Semana',
        slug: '/trabalhista/apostas-da-semana',
        clickable: true
    },
    '/trabalhista/direto-da-corte': {
        label: 'Direto da Corte',
        slug: '/trabalhista/direto-da-corte',
        clickable: true
    },
    '/trabalhista/direto-da-fonte': {
        label: 'Direto da Fonte',
        slug: '/trabalhista/direto-da-fonte',
        clickable: true
    },
    '/trabalhista/giro-nos-trts': {
        label: 'Giro nos TRTs',
        slug: '/trabalhista/giro-nos-trts',
        clickable: true
    },
    '/trabalhista/relatorio-especial': {
        label: 'Relatório Especial',
        slug: '/trabalhista/relatorio-especial',
        clickable: true
    },
    // Saúde
    '/saude': { label: 'Saúde', slug: '/saude', clickable: true },
    '/saude/apostas-da-semana': {
        label: 'Apostas da Semana',
        slug: '/saude/apostas-da-semana',
        clickable: true
    },
    '/saude/bastidores-da-saude': {
        label: 'Bastidores da Saúde',
        slug: '/saude/bastidores-da-saude',
        clickable: true
    },
    '/saude/direto-da-anvisa-ans': {
        label: 'Direto da Anvisa/ANS',
        slug: '/saude/direto-da-anvisa-ans',
        clickable: true
    },
    '/saude/direto-da-corte': {
        label: 'Direto da Corte',
        slug: '/saude/direto-da-corte',
        clickable: true
    },
    '/saude/direto-do-legislativo': {
        label: 'Direto do Legislativo',
        slug: '/saude/direto-do-legislativo',
        clickable: true
    },
    '/saude/matinal': {
        label: 'Matinal',
        slug: '/saude/matinal',
        clickable: true
    },
    '/saude/relatorio-especial': {
        label: 'Relatório Especial',
        slug: '/saude/relatorio-especial',
        clickable: true
    },
    '/saude/alertas': {
        label: 'Alertas',
        slug: '/saude/alertas',
        clickable: true
    },
    '/saude/todos-os-conteudos': {
        label: 'Todos os Conteúdos',
        slug: '/saude/todos-os-conteudos',
        clickable: true
    },

    // Tributos
    '/tributos': { label: 'Tributos', slug: '/tributos', clickable: true },
    '/tributos/apostas-da-semana': {
        label: 'Apostas da Semana',
        slug: '/tributos/apostas-da-semana',
        clickable: true
    },
    '/tributos/direto-do-carf': {
        label: 'Direto do CARF',
        slug: '/tributos/direto-do-carf',
        clickable: true
    },
    '/tributos/direto-da-corte': {
        label: 'Direto da Corte',
        slug: '/tributos/direto-da-corte',
        clickable: true
    },
    '/tributos/direto-do-legislativo': {
        label: 'Direto do Legislativo',
        slug: '/tributos/direto-do-legislativo',
        clickable: true
    },
    '/tributos/matinal': {
        label: 'Matinal',
        slug: '/tributos/matinal',
        clickable: true
    },
    '/tributos/relatorios-especiais': {
        label: 'Relatórios Especiais',
        slug: '/tributos/relatorios-especiais',
        clickable: true
    },
    '/tributos/todos-os-conteudos': {
        label: 'Todos os Conteúdos',
        slug: '/tributos/todos-os-conteudos',
        clickable: true
    },

    // Poder
    '/poder': { label: 'Poder', slug: '/poder', clickable: true },
    '/poder/apostas-da-semana': {
        label: 'Apostas da Semana',
        slug: '/poder/apostas-da-semana',
        clickable: true
    },
    '/poder/impacto-nas-instituicoes': {
        label: 'Impacto nas Instituições',
        slug: '/poder/impacto-nas-instituicoes',
        clickable: true
    },
    '/poder/risco-politico': {
        label: 'Risco Político',
        slug: '/poder/risco-politico',
        clickable: true
    },
    '/poder/alertas': {
        label: 'Alertas',
        slug: '/poder/alertas',
        clickable: true
    },

    // Editorial Poder
    '/poder/executivo': {
        label: 'Executivo',
        slug: '/poder/executivo',
        clickable: true
    },
    '/poder/stf': { label: 'STF', slug: '/poder/stf', clickable: true },
    '/poder/justica': {
        label: 'Justiça',
        slug: '/poder/justica',
        clickable: true
    },
    '/poder/legislativo': {
        label: 'Legislativo',
        slug: '/poder/legislativo',
        clickable: true
    },
    '/poder/todos-os-conteudos': {
        label: 'Todos os Conteúdos',
        slug: '/poder/todos-os-conteudos',
        clickable: true
    }
}
