import { FC } from 'react'
import { colors } from '../theme'

import {
    BreadcrumbContainer,
    BreadcrumbContainerInternal,
    ClickableItem,
    ContainerItem,
    Item,
    LastItem,
    SelectedItem
} from './style'
import { BreadcrumbItem, BreadFromWPAPI, BreadItemsArray } from './interfaces'
import { JotaMaterialArrow, JotaMaterialHome } from '../Icons'
import { breadcrumbRoutesPROOnly } from './pro-routes'

export const BreadHome: BreadcrumbItem = {
    label: 'Início',
    slug: '/',
    clickable: true
}

export const categories = [
    'ecommerce-internacional',
    'ambiente-negocios-oportunidades',
    'proteinas-alternativas-economia-verde',
    'seguranca-juridica-desenvolvimento',
    'seguranca-juridica-investimento',
    'transicao-energetica'
]

/**
 * Make bread URL from POST wp-api
 * @param {BreadFromWPAPI} item
 * @return {BreadcrumbItem[]}
 */
export const makeBreadItemsFromWP = (item: BreadFromWPAPI) => {
    const breadItems = [BreadHome]

    if (item.title) {
        breadItems.push({
            label: item.title,
            slug: `/${item.slug}`,
            clickable: true
        })
    }

    if (item.children?.title) {
        breadItems.push({
            label: item.children.title,
            slug: item.slug
                ? `/${item.slug}/${item.children.slug}`
                : `/${item.children.slug}`,
            clickable: true
        })
    }

    return breadItems
}

/**
 * Make bread URL from OS
 * @param {string} url
 * @return {any}
 */
export const makeBreadItemsFromOS = (url: string) => {
    const breadItems = [BreadHome]

    const tempItems = url.split('/').filter((item) => item !== '')
    let accumulatedPath = ''

    tempItems.forEach((item) => {
        accumulatedPath += `/${item}`
        const fixedRoute = breadcrumbRoutesPROOnly[accumulatedPath]

        if (fixedRoute) {
            breadItems.push(fixedRoute)
        }
    })

    return breadItems
}

/**
 * Breadcrumb component
 * @param {BreadItemsArray} items
 * @return {JSX.Element}
 */
const BreadcrumbComponentJU: FC<BreadItemsArray> = ({ items }) => {
    return (
        <BreadcrumbContainer>
            <BreadcrumbContainerInternal data-testid='breadcrumb-container'>
                <JotaMaterialHome fill={colors.gray.jotaGrayDark} />
                {items.length > 0 &&
                    items?.map((item, index) =>
                        returnClickableItem(index, items)
                    )}
            </BreadcrumbContainerInternal>
        </BreadcrumbContainer>
    )
}

export default BreadcrumbComponentJU

/**
 * Return clickable item
 * @param {number} index
 * @param {BreadcrumbItem[]} items
 * @return {JSX.Element}
 */
export const returnClickableItem = (index: number, items: BreadcrumbItem[]) => {
    // If it is the last item, return the selected item
    if (index === items.length - 1) {
        return (
            <SelectedItem>
                {items[index]?.clickable === true ? (
                    <ContainerItem
                        key={items[index].label}
                        href={items[index].slug}
                    >
                        <ClickableItem>{items[index].label}</ClickableItem>
                    </ContainerItem>
                ) : (
                    <LastItem>{items[index].label}</LastItem>
                )}
            </SelectedItem>
        )
    } else {
        return (
            <>
                {items[index]?.clickable === true ? (
                    <ContainerItem
                        key={items[index].label}
                        href={items[index].slug}
                    >
                        <ClickableItem>
                            <Item>{items[index].label}</Item>
                        </ClickableItem>
                    </ContainerItem>
                ) : (
                    <Item key={items[index].label}>{items[index].label}</Item>
                )}
                <JotaMaterialArrow
                    fill={colors.transparent.jotaTGray50}
                    type='right'
                />
            </>
        )
    }
}
